$bg-color-main: #000;
$black: #000;
$white: #fff;
$black200: #202020;
$black100: #424242;
$gray100: #757575;
$gray200: #444;
$gray300: #505050;
$gray: #dbdde1;
$blue: #1976d2;
$copyColor: #d9edd9;
$pink: #dc20ff;
