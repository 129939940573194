@import "@styles/variables";

.container {
  width: 100%;
  display: flex;
  padding: 19px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 36px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.logo {
  font-family: "DM Sans";
  color: $white;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  span {
    color: $pink;
    font-size: 24px;
    font-weight: 800;
    font-family: "DM Sans";
  }
}

.logo_text {
  font-family: "Plus Jakarta Sans";
  color: $white;
  text-align: center;
  font-size: 32.939px;
  font-weight: 800;
}

.menu {
  display: flex;
  gap: 64px;
  button {
    background-color: transparent;
    border: none;
    color: $white;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    cursor: pointer;
  }
}
.btn_get_started {
  display: flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: $white;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  border: none;
  cursor: pointer;
}

.logo_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  figure {
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 1100px) {
  .logo_text {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .logo_text {
    font-size: 20px;
  }
  .menu {
    gap: 20px;
    button {
      font-size: 16px;
    }
  }
  .btn_get_started {
    padding: 10px 14px;
    gap: 10px;
    font-size: 16px;
  }
}

@media (max-width: 769px) {
  .logo_text {
    font-size: 20px;
  }
  .menu {
    gap: 20px;
    button {
      font-size: 12px;
    }
  }
  .btn_get_started {
    padding: 8px 12px;
    gap: 10px;
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .menu {
    gap: 15px;
    button {
      font-size: 12px;
    }
  }
  .btn_get_started {
    padding: 8px 12px;
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 8px 4px;
    justify-content: center;
  }
  .logo {
    font-size: 20px;
    span {
      font-size: 20px;
    }
  }
}
