@import "@styles/variables";

.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: $bg-color-main;

  h1 {
    font-size: 6rem;
    color: $pink;
  }

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.2rem;
    margin: 1rem 0;
  }

  .home_link {
    padding: 0.8rem 1.5rem;
    background-color: $pink;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      transition: 0.5s;
      transform: scale(1.3);
    }
  }
}
