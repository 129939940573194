@import "@styles/variables";
.container {
  display: flex;
  width: 320px;

  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background-image: url("../../../assets/img/qr_bg.webp");
  background-repeat: round;
  background-size: cover;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  color: $white;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  span {
    color: $pink;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

.qr_img img {
  width: 226px;
  height: 226px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.address_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: $white;
  }
}

.address {
  color: $white;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 226px;
  margin-bottom: 12px;
}

.close_modal {
  position: absolute;
  right: 15px;
  bottom: 1px;
  cursor: pointer;
}
