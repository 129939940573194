@import "@styles/variables";

.container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.address_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 6px 16px;
  display: flex;
  height: 40px;
  align-items: center;
  gap: 32px;
  border: none;
  background: rgba(222, 227, 237, 0.15);
}

.address {
  color: $white;
  font-size: 14px;
  font-weight: 400;
}

.address_wrapper {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    &[data-clicked="true"] {
      background: linear-gradient(
          0deg,
          rgba(106, 205, 110, 0.15) 0%,
          rgba(106, 205, 110, 0.15) 100%
        ),
        #212124;
    }
    background: #2e2e31;
  }
}

.copy_icon {
  display: flex;
  align-items: center;
}

.label {
  color: $white;
  font-size: 20px;
  font-weight: 600;
}

.wrapper {
  display: flex;
  gap: 8px;
}

.qr {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #dc20ff;
  position: relative;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: #e141ff;
  }
  &:active {
    background: #bb1bd9;
  }
  svg {
    position: absolute;
    cursor: pointer;
  }
}

// .clicked {
//   background-color: $copyColor;
// }

.text_copy {
  position: absolute;
  bottom: 45px;
  right: 0px;
  padding: 8px 16px;
  border-radius: 12px;
  background: #252528;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
  }
  .address_wrapper {
    gap: 8px;
    width: 100%;
  }
  .label {
    font-size: 16px;
  }
}
