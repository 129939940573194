.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 128px;
  margin: auto;
  margin-top: 200px;
}

.wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
}

[data-exist="false"] {
  margin: auto;
}
[data-exist="true"] {
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .container {
    padding: 0;
  }
}
