.iconsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.supportText {
  color: #c2c9d6;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
  }
  &:nth-child(n + 2) {
    margin-left: -8px;
  }
}

.icon-wrapper-png {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 35px;
    height: 35px;
  }
  &:nth-child(n + 2) {
    margin-left: -8px;
  }
}
