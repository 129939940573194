@import "@styles/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 52px;
  background: transparent;
  background-size: cover;
  padding: 0px 128px;
}

.Modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  margin-bottom: 20px;
  text-align: center;
  color: $white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  span {
    color: #dc20ff;
    font-size: 16px;
    font-weight: 800;
  }
}

.wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.wrapper_info {
  padding: 20px 0;
}

.wrapper_addresses {
  padding: 32px 0;
}

.search_wrapper {
  padding: 0;
}

@media (max-width: 1024px) {
  .container {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 20px 0;
  }
}
