@import "@styles/variables";
.burger-menu-container {
  position: relative;
}

.burger-icon {
  width: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;

  .line {
    background-color: $white;
    height: 3px;
    width: 100%;
    transition: all 0.3s ease;

    &.open:nth-child(1) {
      transform: rotate(45deg) translateY(9px);
    }

    &.open:nth-child(2) {
      opacity: 0;
    }

    &.open:nth-child(3) {
      transform: rotate(-45deg) translateY(-9px);
    }
  }
}

.menu {
  &.open {
    right: 0;
  }

  .close-btn {
    background: none;
    border: none;
    color: $white;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }

  nav {
    margin-top: 50px;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 20px 0;

        a {
          text-decoration: none;
          color: $white;
          font-size: 16px;
          display: block;
          padding: 20px 0;
          text-align: center;
          background-color: #222;
          border-radius: 8px;
          transition: background-color 0.3s;

          &:hover {
            background-color: $white;
            color: $black200;
          }
        }
      }
    }
  }
}
