@import "@styles/variables";
.container {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}

.text {
  color: #7d8596;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
