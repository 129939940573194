@import "@styles/variables";

.layout {
  height: 100%;
  width: 100%;
}

.main {
  height: 100%;
}
