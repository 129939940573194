@import "@styles/variables";

.container {
  width: 100%;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 40px;
  border: 1px solid rgba(80, 80, 80, 0.5);
  background: transparent;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  max-width: 500px;
}

.input_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  &[data-active="true"] {
    background: white;
  }

  &[data-active="false"] {
    background: #f0f1f4;
  }
  display: flex;
  height: 40px;
  padding: 4px 4px 4px 12px;
  align-items: center;
  border-radius: 20px;
  gap: 8px;
  .input_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    width: 100%;
    color: $pink;
    font-size: 12px;
    font-weight: 500;
    word-wrap: break-word;
  }
}

.close {
  cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    padding: 8px 26px 8px 4px;
  }

  .input_container {
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
