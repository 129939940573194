@import "@styles/variables";

.App {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 26px 80px 120px 80px;
  background-color: $bg-color-main;
  position: relative;
}
.App[data-image="true"] {
  background-image: url("./assets/img/Mockup.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.layout {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container {
  width: 100%;
  height: 100%;
}

@media (max-width: 1100px) {
  .App {
    padding: 26px 60px 120px 60px;
  }
}

@media (max-width: 1024px) {
  .App {
    padding: 26px 50px 120px 50px;
  }
}

@media (max-width: 769px) {
  .App {
    padding: 26px 30px 120px 30px;
  }
}
