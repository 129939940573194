@import "@styles/variables";

.logo {
  font-family: "DM Sans";

  color: $white;
  text-align: center;
  font-size: 76px;
  font-weight: 700;
  span {
    font-family: "DM Sans";

    color: #dc20ff;
    font-size: 76px;
    font-weight: 800;
  }
}

.txt {
  color: $white;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  margin-bottom: 32px;
}

@media (max-width: 600px) {
  .logo {
    font-size: 48px;
    span {
      font-size: 48px;
    }
  }
  .txt {
    font-size: 16px;
    font-weight: 600;
  }
}
