@import "@styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.row_container {
  background-color: transparent;
  margin-top: 24px;
}

.name_wrapper {
  display: flex;
  gap: 12px;
  align-items: end;
  margin-bottom: 12px;
  svg {
    cursor: pointer;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 8px;
}
.view {
  overflow: hidden;
  color: $blue;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-decoration-line: underline;
  color: #e450ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.networkName {
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.link_text {
  margin-right: 4px;
  color: #e450ff;
}

@media (max-width: 600px) {
  .name_wrapper {
    justify-content: space-between;
  }
}
