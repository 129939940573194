@import "@styles/variables";

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(222, 227, 237, 0.15);
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 16px 28px;
}

.left_column {
  display: flex;
  align-items: center;
  gap: 10px;
  .symbol {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  .network {
    overflow: hidden;
    color: #878797;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
  }
}

.count {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #878797;
}

.right_column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .coin {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  span {
    color: #878797;

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-left: 4px;
  }
  .dollar {
    color: #878797;
    text-align: end;

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

.icon_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon_wrapper img {
  width: 30px;
  height: 30px;
}

@media (max-width: 600px) {
  .container {
    padding: 12px 16px;
  }
  .left_column {
    .symbol {
      font-size: 13px;
    }
    .network {
      font-size: 13px;
    }
  }
  .count {
    font-size: 13px;
  }

  .right_column {
    .coin {
      font-size: 13px;
    }
    span {
      font-size: 13px;
    }
  }
}
