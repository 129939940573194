@import "@styles/variables";

.container {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  background: #212124;
  box-shadow: 0px 4px 8px 0px rgba(23, 25, 28, 0.15),
    0px 0px 28px 0px rgba(23, 25, 28, 0.1);
  cursor: text;

  &:hover {
    background-color: #2b2b2e;
    .input_wrapper {
      background-color: #2b2b2e;

      input {
        background-color: #2b2b2e;
      }
    }
  }

  .logo {
    font-family: "DM Sans";
    position: relative;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    span {
      font-family: "DM Sans";
      color: #dc20ff;
      font-size: 20px;
      font-weight: 800;
    }
  }

  .input_wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background: #212124;

    display: flex;
    height: 40px;
    padding: 4px 4px 4px 0px;
    align-items: center;
    border-radius: 20px;
    gap: 8px;
    .input_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    input {
      cursor: text;
      width: 100%;
      background: #212124;
      color: white;
      padding: 4px 4px 4px 12px;
      align-items: center;
      height: 100%;
      border: none;
      &:focus {
        border-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
    .qr_wrapper {
      display: flex;
      gap: 8px;
    }
    .btn_search {
      border: none;
      width: 136px;
      border-radius: 12px;
      background: #dc20ff;
      box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.15);
      padding: 0px 24px;
      color: $white;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: #e141ff;
      }

      &:active {
        background: #bb1bd9;
      }
    }
    .btn_qr {
      width: 40px;
      height: 40px;
      gap: 8px;
      border-radius: 12px;
      background: rgba(219, 221, 225, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      &:hover {
        background-color: rgba(219, 221, 225, 0.2);
      }
      &:active {
        background-color: rgba(219, 221, 225, 0.25);
      }
    }
  }

  .differ {
    position: absolute;
    right: -18px;
    bottom: -15px;
    width: 1px;
    height: 54px;
    stroke-width: 1px;
    background-color: rgba(56, 56, 61, 0.7);
  }

  .search_icon {
    margin-top: 3px;
  }

  @media (max-width: 600px) {
    .input_container {
      justify-content: flex-start !important;
    }
    .logo {
      display: none;
    }
    .container {
      padding: 8px;
    }
    .btn_search {
      display: none;
    }
    .input_wrapper {
      input {
        width: 80%;
      }
    }
  }
}
