@import "@styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sum {
  display: flex;
  align-items: center;
}

.total {
  color: rgba(255, 255, 255, 0.6);
  margin-right: 6px;
  font-size: 16px;
  font-weight: 400;
}

.price {
  color: $white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 2px;
}

.currency {
  color: rgba(255, 255, 255, 0.6);

  text-align: right;
  font-size: 20px;
  font-weight: 500;
}

.contained {
  color: $white;
  font-size: 20px;
  font-weight: 600;
  span {
    color: rgba(255, 255, 255, 0.6);

    font-size: 20px;
    font-weight: 500;
  }
}
.dot {
  height: 1px;
  background-color: rgba(18, 23, 33, 0.15);
}

@media (max-width: 600px) {
  .contained {
    font-size: 16px;
    span {
      font-size: 16px;
    }
  }
  .total {
    font-size: 13px;
  }
  .price {
    font-size: 13px;
  }
  .currency {
    font-size: 13px;
    font-weight: 600;
    color: $black100;
  }
}
